"use client"
import Link from "next/link";
import styles from './style.module.css'
import {usePathname} from "next/navigation";

export const NavigationLink = ({name, url, onClick}: {name: string, url: string, onClick?: () => void}) => {
  const activePath = usePathname();
  const matchesPath = activePath.startsWith(url) && (url !== '/' || activePath === url)
  return (
      <Link
        href={url}
        onClick={onClick}
        className={matchesPath ? `${styles.current} ${styles.navigationLink}` : styles.navigationLink}
      >
        {name}
      </Link>
  )
}