"use client"
import {NavigationLink} from "./NavigationLink";
import styles from './style.module.css'
import {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faX} from "@fortawesome/free-solid-svg-icons";


const links: Array<{name: string, url: string}> = [{
  name: "Main Links",
  url: '/'
},{
  name: "NSFW Links",
  url: '/lewd'
},{
  name: "Photos",
  url: '/photos'
}]

export const Navigation = () => {
  const [isOffset, setIsOffset] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => setIsOffset(window.scrollY > 0);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const toggleOpen = useCallback(() => setIsOpen((cur) => !cur), [])

  return (
    <div className={`${isOffset ? styles.offsetNavigationRow : undefined} ${styles.navigationRow}`}>
      <div className={styles.nav}>
        {links.map((link) => (
          <NavigationLink {...link} key={link.url}/>
        ))}
      </div>
      <div className={styles.navSmall}>
        <FontAwesomeIcon
          icon={isOpen ? faX : faBars}
          onClick={toggleOpen}
          size='2x'
          className={styles.navSmallToggle}
        />
        <div className={`${isOpen ? undefined : styles.sidebarClosed} ${styles.sidebar}`}>
          {links.map((link) => (
            <NavigationLink {...link} key={link.url} onClick={toggleOpen}/>
          ))}
        </div>
      </div>
    </div>
  )
}